import React from 'react';
import AuthoredBookItem from "./Authored_book__item";
import Book_1 from '../../../assets/book/Book_1.png';
import Book_2 from '../../../assets/book/Book_2.png';


function AuthoredBooks() {
  return (
    <div className={'lg:py-[200px] xm:py-[100px]'}>
      <div className={'container'}>
        <h1 className={'text-center md:text-72 xm:text-[50px]'}>Authored Books</h1>
        <div className={'grid grid-flow-row sm:grid-cols-2 xm:grid-cols-1 gap-[30px] mt-[62px]'}>
          <AuthoredBookItem
            link={'https://www.amazon.in/Learning-Penetration-Testing-Swaroop-Yermalkar-ebook/dp/B017XSFKHO/ref=sr_1_2?dchild=1&amp&qid=1619076845&amp&refinements=p_27%3ASwaroop+Yermalkar&amp&s=books&amp&sr=1-2'}
            className={'xl:mt-6 lg:mt-9 md:mt-10 xm:mb-10 sm:mb-0'}
            img={Book_1}
            title={'Learning iOS Penetration Testing'}
            detail={'This book will help you to secure your iOS applications and uncover hidden vulnerabilities by conducting penetration tests.'}
            reating={'18'}
          />
          <AuthoredBookItem
            link={'https://www.amazon.in/Ethical-Guide-WI-FI-Hacking-Security/dp/9383952156/ref=sr_1_1?dchild=1&keywords=an+ethical+guide+to+wifi+hacking&qid=1619076779&sr=8-1'}
            img={Book_2}
            title={'An Ethical Guide To WI-FI Hacking and Security'}
            detail={'The book will guide you on various possible attacks on WiFi networks such as WEP, WPA/WPA2, and WPA Enterprise using a manual and automated approach.'}
            reating={'44'}
          />
        </div>
      </div>
    </div>
  );
}

export default AuthoredBooks;