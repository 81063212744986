import React from "react";
import "../experience/custom_resume_css.css";
import SchoolLOGO from "../../../assets/logo/school_logo.png";

function EducationSection() {
  return (
    <div className={"container pt-[60px] pb-[131px]"}>
      <div className={"custom_border__resume pb-[60px]"}>
        <h2
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="10"
          data-aos-offset="0"
          className={
            "lg:text-[48px] xm:text-[38px] md:text-[38px] font-semibold font_kanit custom_top__bar"
          }
        >
          Education
        </h2>
      </div>
      <div data-aos="zoom-in" className={"flex justify-center"}>
        <div
          className={
            "lg:px-[147px] md:px-[80px] md:py-[40px] sm:px-[54px] xm:px-[20px] xm:py-[40px] border border-[#E8E8E8] rounded-[18px]"
          }
        >
          <div
            className={
              "flex items-center justify-center md:gap-[47px] xm:gap-[26px]"
            }
          >
            <img src={SchoolLOGO} alt={"school_logo"} />
            <div>
              <h4
                className={
                  "md:text-[24px] xm:text-[18px] font-semibold leading-[38px] text-[#272728] font_kanit mb-[10px]"
                }
              >
                MIT College of Engineering, Pune
              </h4>
              <p className={"text-[#A5A5A5] md:text-[16px] xm:text-[14px]"}>
                MIT College of Engineering, Pune
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationSection;
