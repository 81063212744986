import React from 'react';
import './custom_resume_css.css';
import ResumeExperience from "./ResumeExperience";


function ResumeSection() {
  return (
    <>
      <div className={'sm:pt-[170px] sm:pb-[120px] xm:pt-[100px] xm:pb-[80px] custom_bg'}>
        <h1 className={'text-center sm:text-72 xm:text-[56px] font_kanit font-semibold'}>Resume</h1>
      </div>
      <div className={'container pt-[100px] pb-8'}>
        <div className={'grid grid-flow-row md:grid-cols-3'}>
          <div className={'custom_border__resume xm:mb-20 md:mb-0'}>
            <h2 data-aos="zoom-in" className={'lg:text-[48px] xm:text-[28px] md:text-[38px] font-semibold font_kanit custom_top__bar'}>Experience</h2>
          </div>
          <div className={'col-span-2'}>
            <ResumeExperience/>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResumeSection;