import React from 'react';
import './custom_skill__css.css';
import ProgressBar from "./ProgressBar";

function SkillsSection() {
  return (
    <div className={'sm:pb-[200px] xm:mb-[100px]'}>
      <div className={'pb-[60px]'}>
        <h2 className={'sm:text-[48px] xm:text-[38px] font-semibold font_kanit custom_skill_bar'}>My skills</h2>
      </div>
      <div className={'grid grid-flow-row grid-cols-12'}>
        <div className={'lg:col-start-3 lg:col-span-8 md:col-start-2 md:col-span-10 xm:col-span-12'}>
          <ProgressBar />
        </div>
      </div>
    </div>
  );
}

export default SkillsSection;