import React from 'react';
import ReactPlayer from "react-player";
import './custom_video_css.css';

function TalksVideo() {
  return (
    <div className={'container pt-[100px] pb-[200px]'}>
      <div className={'grid grid-flow-row md:grid-cols-2 xm:grid-cols-1 gap-[30px]'}>
        <div className={'w-auto mb-12'}>
          <ReactPlayer className="react-player" width='100%' url={'https://youtu.be/loHywYcwjmo'} controls={true} />
          <h6 className={'text-[26px] font-semibold font_kanit mt-[30px]'}>AppSec USA 2018</h6>
        </div>
        <div className={'w-auto mb-12'}>
          <ReactPlayer className="react-player"  width='100%' url={'https://youtu.be/zRFW3ifL0zw'} controls={true} />
          <h6 className={'text-[26px] font-semibold font_kanit mt-[30px]'}>Null Bhopal</h6>
        </div>
        <div className={'w-auto'}>
          <ReactPlayer className="react-player"  width='100%' url={'https://youtu.be/uiKKz9Aolo4'} controls={true} />
          <h6 className={'text-[26px] font-semibold font_kanit mt-[30px]'}>AppSec Israel 2018</h6>
        </div>
        <div className={'w-auto'}>
          <ReactPlayer className="react-player"  width='100%' url={'https://youtu.be/BOKRPm4WteI'} controls={true} />
          <h6 className={'text-[26px] font-semibold font_kanit mt-[30px]'}>SEC-T</h6>
        </div>
      </div>
    </div>
  );
}

export default TalksVideo;