import React from 'react';
import './custom_source-product__css.css';

function SourceProject() {
  return (
    <div className={'container pt-[170px] pb-[100px]'}>
      <div className={'mb-[60px]'}>
        <h2 className={'md:text-[48px] xm:text-[38px] font-semibold font_kanit custom_border__source-product'}>Open Source Projects</h2>
      </div>
      <div className={'grid grid-flow-row grid-cols-12 mt-[30px]'}>
        <div className={'md:col-start-3 md:col-span-8 xm:col-span-12'}>
          <div className={'border border-[#E8E8E8] rounded-[18px] p-10'}>
            <a href={'https://igoatapp.com/'} target={'blank'} className={'text-[#5E3AEE] block sm:text-2xl xm:text-[22px] font-semibold leading-[160%]'}>OWASP iGoat</a>
            <span className={'text-[#A5A5A5] sm:text-base xm:text-[14px]'}>Tech Stack: Objective C, OWASP Mobile Top 10</span>
            <p className={'mt-[32px] text-[#272728] leading-[160%]'}>Role: iGoat is a learning tool for iOS developers (iPhone, iPad, etc.) and mobile app pentesters. iGoat was inspired by the WebGoat project, and has a similar conceptual flow to it.As such, iGoat is a safe environment where iOS developers can learn about the major security pitfalls they face as well as how to avoid them.It is made up of a series of lessons that each teach a single (but vital) security lesson.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SourceProject;