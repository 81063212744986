import React from 'react'
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Navbar from "./component/menu/Navbar";
import Home from "./component/page/Home";
import Resume from "./component/page/Resume"
import Footer from "./component/footer/Footer";
import Portfolio from "./component/page/Portfolio";
import Contact from "./component/page/Contact";
import Talks from "./component/page/Talks";
import ScrollToTop from "./component/ScrollToTop";

import AOS from 'aos';
import 'aos/dist/aos.css';



function App() {
  AOS.init();
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/resume" element={<Resume />}/>
          <Route path="/portfolio" element={<Portfolio />}/>
          <Route path="/talks" element={<Talks />}/>
          <Route path="/contact" element={<Contact />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
