import React from "react";

import Map from "./Map";

function SwaroopTravel() {
  return (
    <>
      <div className={'lg:mb-[200px] xm:mb-0'}>
        <div className={'container'}>
          <h1 className={'text-center md:text-72 xm:text-[50px]'}>Swaroop's Travel</h1>
        </div>
        <div className={'container w-full sm:h-[550px] xm:h-[400px] overflow-hidden'}>
          <div className={'grid grid-flow-row grid-cols-12'}>
            <div className={'col-start-2 col-span-10'}>
              <Map />
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default SwaroopTravel;