import React from 'react';

function AuthoredBookItem(props) {
  const svg = [
    {
      id: 1,
    svg: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9184 12.3201C15.6594 12.5711 15.5404 12.9341 15.5994 13.2901L16.4884 18.2101C16.5634 18.6271 16.3874 19.0491 16.0384 19.2901C15.6964 19.5401 15.2414 19.5701 14.8684 19.3701L10.4394 17.0601C10.2854 16.9781 10.1144 16.9341 9.93939 16.9291H9.66839C9.57439 16.9431 9.48239 16.9731 9.39839 17.0191L4.96839 19.3401C4.74939 19.4501 4.50139 19.4891 4.25839 19.4501C3.66639 19.3381 3.27139 18.7741 3.36839 18.1791L4.25839 13.2591C4.31739 12.9001 4.19839 12.5351 3.93939 12.2801L0.328388 8.78011C0.0263875 8.48711 -0.0786125 8.04711 0.0593875 7.65011C0.193388 7.25411 0.535388 6.96511 0.948388 6.90011L5.91839 6.17911C6.29639 6.14011 6.62839 5.91011 6.79839 5.57011L8.98839 1.08011C9.04039 0.980107 9.10739 0.888107 9.18839 0.810107L9.27839 0.740107C9.32539 0.688107 9.37939 0.645107 9.43939 0.610107L9.54839 0.570107L9.71839 0.500107H10.1394C10.5154 0.539107 10.8464 0.764107 11.0194 1.10011L13.2384 5.57011C13.3984 5.89711 13.7094 6.12411 14.0684 6.17911L19.0384 6.90011C19.4584 6.96011 19.8094 7.25011 19.9484 7.65011C20.0794 8.05111 19.9664 8.49111 19.6584 8.78011L15.9184 12.3201Z" fill="#FFC023"/>
    </svg>
    },
    {
      id: 2,
      svg: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9184 12.3201C15.6594 12.5711 15.5404 12.9341 15.5994 13.2901L16.4884 18.2101C16.5634 18.6271 16.3874 19.0491 16.0384 19.2901C15.6964 19.5401 15.2414 19.5701 14.8684 19.3701L10.4394 17.0601C10.2854 16.9781 10.1144 16.9341 9.93939 16.9291H9.66839C9.57439 16.9431 9.48239 16.9731 9.39839 17.0191L4.96839 19.3401C4.74939 19.4501 4.50139 19.4891 4.25839 19.4501C3.66639 19.3381 3.27139 18.7741 3.36839 18.1791L4.25839 13.2591C4.31739 12.9001 4.19839 12.5351 3.93939 12.2801L0.328388 8.78011C0.0263875 8.48711 -0.0786125 8.04711 0.0593875 7.65011C0.193388 7.25411 0.535388 6.96511 0.948388 6.90011L5.91839 6.17911C6.29639 6.14011 6.62839 5.91011 6.79839 5.57011L8.98839 1.08011C9.04039 0.980107 9.10739 0.888107 9.18839 0.810107L9.27839 0.740107C9.32539 0.688107 9.37939 0.645107 9.43939 0.610107L9.54839 0.570107L9.71839 0.500107H10.1394C10.5154 0.539107 10.8464 0.764107 11.0194 1.10011L13.2384 5.57011C13.3984 5.89711 13.7094 6.12411 14.0684 6.17911L19.0384 6.90011C19.4584 6.96011 19.8094 7.25011 19.9484 7.65011C20.0794 8.05111 19.9664 8.49111 19.6584 8.78011L15.9184 12.3201Z" fill="#FFC023"/>
      </svg>
    },
    {
      id: 3,
      svg: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9184 12.3201C15.6594 12.5711 15.5404 12.9341 15.5994 13.2901L16.4884 18.2101C16.5634 18.6271 16.3874 19.0491 16.0384 19.2901C15.6964 19.5401 15.2414 19.5701 14.8684 19.3701L10.4394 17.0601C10.2854 16.9781 10.1144 16.9341 9.93939 16.9291H9.66839C9.57439 16.9431 9.48239 16.9731 9.39839 17.0191L4.96839 19.3401C4.74939 19.4501 4.50139 19.4891 4.25839 19.4501C3.66639 19.3381 3.27139 18.7741 3.36839 18.1791L4.25839 13.2591C4.31739 12.9001 4.19839 12.5351 3.93939 12.2801L0.328388 8.78011C0.0263875 8.48711 -0.0786125 8.04711 0.0593875 7.65011C0.193388 7.25411 0.535388 6.96511 0.948388 6.90011L5.91839 6.17911C6.29639 6.14011 6.62839 5.91011 6.79839 5.57011L8.98839 1.08011C9.04039 0.980107 9.10739 0.888107 9.18839 0.810107L9.27839 0.740107C9.32539 0.688107 9.37939 0.645107 9.43939 0.610107L9.54839 0.570107L9.71839 0.500107H10.1394C10.5154 0.539107 10.8464 0.764107 11.0194 1.10011L13.2384 5.57011C13.3984 5.89711 13.7094 6.12411 14.0684 6.17911L19.0384 6.90011C19.4584 6.96011 19.8094 7.25011 19.9484 7.65011C20.0794 8.05111 19.9664 8.49111 19.6584 8.78011L15.9184 12.3201Z" fill="#FFC023"/>
      </svg>
    },
    {
      id: 4,
      svg: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9184 12.3201C15.6594 12.5711 15.5404 12.9341 15.5994 13.2901L16.4884 18.2101C16.5634 18.6271 16.3874 19.0491 16.0384 19.2901C15.6964 19.5401 15.2414 19.5701 14.8684 19.3701L10.4394 17.0601C10.2854 16.9781 10.1144 16.9341 9.93939 16.9291H9.66839C9.57439 16.9431 9.48239 16.9731 9.39839 17.0191L4.96839 19.3401C4.74939 19.4501 4.50139 19.4891 4.25839 19.4501C3.66639 19.3381 3.27139 18.7741 3.36839 18.1791L4.25839 13.2591C4.31739 12.9001 4.19839 12.5351 3.93939 12.2801L0.328388 8.78011C0.0263875 8.48711 -0.0786125 8.04711 0.0593875 7.65011C0.193388 7.25411 0.535388 6.96511 0.948388 6.90011L5.91839 6.17911C6.29639 6.14011 6.62839 5.91011 6.79839 5.57011L8.98839 1.08011C9.04039 0.980107 9.10739 0.888107 9.18839 0.810107L9.27839 0.740107C9.32539 0.688107 9.37939 0.645107 9.43939 0.610107L9.54839 0.570107L9.71839 0.500107H10.1394C10.5154 0.539107 10.8464 0.764107 11.0194 1.10011L13.2384 5.57011C13.3984 5.89711 13.7094 6.12411 14.0684 6.17911L19.0384 6.90011C19.4584 6.96011 19.8094 7.25011 19.9484 7.65011C20.0794 8.05111 19.9664 8.49111 19.6584 8.78011L15.9184 12.3201Z" fill="#FFC023"/>
      </svg>
    },
    {
      id: 5,
      svg: <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.9184 12.3201C15.6594 12.5711 15.5404 12.9341 15.5994 13.2901L16.4884 18.2101C16.5634 18.6271 16.3874 19.0491 16.0384 19.2901C15.6964 19.5401 15.2414 19.5701 14.8684 19.3701L10.4394 17.0601C10.2854 16.9781 10.1144 16.9341 9.93939 16.9291H9.66839C9.57439 16.9431 9.48239 16.9731 9.39839 17.0191L4.96839 19.3401C4.74939 19.4501 4.50139 19.4891 4.25839 19.4501C3.66639 19.3381 3.27139 18.7741 3.36839 18.1791L4.25839 13.2591C4.31739 12.9001 4.19839 12.5351 3.93939 12.2801L0.328388 8.78011C0.0263875 8.48711 -0.0786125 8.04711 0.0593875 7.65011C0.193388 7.25411 0.535388 6.96511 0.948388 6.90011L5.91839 6.17911C6.29639 6.14011 6.62839 5.91011 6.79839 5.57011L8.98839 1.08011C9.04039 0.980107 9.10739 0.888107 9.18839 0.810107L9.27839 0.740107C9.32539 0.688107 9.37939 0.645107 9.43939 0.610107L9.54839 0.570107L9.71839 0.500107H10.1394C10.5154 0.539107 10.8464 0.764107 11.0194 1.10011L13.2384 5.57011C13.3984 5.89711 13.7094 6.12411 14.0684 6.17911L19.0384 6.90011C19.4584 6.96011 19.8094 7.25011 19.9484 7.65011C20.0794 8.05111 19.9664 8.49111 19.6584 8.78011L15.9184 12.3201Z" fill="#FFC023"/>
      </svg>
    }
  ]
  return (
    <div>
        <img src={props.img} alt="" />
        <div className={'mt-30 mb-5'}>
          <a href={props.link} target={'blank'} className={'text-25 font-semibold font_kanit hover:text-blue-600'}>{props.title}</a>
        </div>
        <p className={'mb-11 text-ellipsis'}>{props.detail}</p>
        <div className={'flex items-center gap-[10px]'}>
          {
            svg.map((svg)=>{
              return (
                <li key={svg.id} className={props.className}>{svg.svg}</li>
              )
            })
          }
          <span className={props.className}>{`(${props.reating})`}</span>
        </div>
      </div>
  );
}

export default AuthoredBookItem;