import React from 'react';
import Hero from "../home/Hero/Hero";
import Works from "../home/work/Works";
import About from "../home/about/About";
import AuthoredBooks from "../home/book/Authored_Books";
import SwaroopTravel from "../home/travel/SwaroopTravel";

function Home() {
  return (
    <>
      <Hero />
      <About />
      <Works />
      <AuthoredBooks />
      <SwaroopTravel />
    </>
  );
}

export default Home;