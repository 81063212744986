import React from 'react';
import ResumeSection from "../resume/experience/ResumeSection";
import EducationSection from "../resume/education/EducationSection";
import Certificates from "../resume/certificates/Certificates";
import SourceProject from "../resume/source_project/SourceProject";
import SkillsSection from "../resume/skills/SkillsSection";

function Resume() {
  return (
    <>
      <ResumeSection />
      <EducationSection />
      <Certificates />
      <SourceProject />
      <SkillsSection />
    </>
  );
}

export default Resume;