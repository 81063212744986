import React from 'react';
import '../resume/experience/custom_resume_css.css';
import ContactForm from "../contact/ContactForm";

function Contact() {
  return (
    <>
      <div className={'sm:pt-[170px] sm:pb-[120px] xm:pt-[100px] xm:pb-[80px] custom_bg'}>
        <h1 className={'text-center sm:text-72 xm:text-[56px] font_kanit font-semibold'}>Contact</h1>
      </div>
      <div className={'container'}>
        <ContactForm />
      </div>
    </>
  );
}

export default Contact;