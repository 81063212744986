import React from 'react';
import './custom_progressbar__css.css';

function ProgressBar() {
  return (
    <div>
      <div className="skills_section">
        <div className="skills_main">
          <div className="skill_bar">
            <div className="info">
              <p className={'xm:mb-6 md:mb-0'}>Vulnerability Assessment & Penetration Testing</p>
              <p className={'text-black font-bold font_poppins text-base'}>90%</p>
            </div>
            <div className="bar">
              <span className="f1"></span>
            </div>
          </div>
          <div className="skill_bar">
            <div className="info">
              <p className={'xm:mb-6 md:mb-0'}>Web, API, iOS & Android Application Pentesting</p>
              <p className={'text-black font-bold font_poppins text-base'}>95%</p>
            </div>
            <div className="bar">
              <span className="f2"></span>
            </div>
          </div>
          <div className="skill_bar">
            <div className="info">
              <p className={'xm:mb-6 md:mb-0'}>Network, AWS, Azure and Google Cloud Security Audits</p>
              <p className={'text-black font-bold font_poppins text-base'}>90%</p>
            </div>
            <div className="bar">
              <span className="f3"></span>
            </div>
          </div>
          <div className="skill_bar">
            <div className="info">
              <p className={'xm:mb-6 md:mb-0'}>Exploit Dev, Lateral Movement, AV Bypass Techniques</p>
              <p className={'text-black font-bold font_poppins text-base'}>85%</p>
            </div>
            <div className="bar">
              <span className="f4"></span>
            </div>
          </div>
          <div className="skill_bar">
            <div className="info">
              <p className={'xm:mb-6 md:mb-0'}>Security Automation, OSINT, Threat Modelling</p>
              <p className={'text-black font-bold font_poppins text-base'}>87%</p>
            </div>
            <div className="bar">
              <span className="f5"></span>
            </div>
          </div>
          <div className="skill_bar">
            <div className="info">
              <p className={'xm:mb-6 md:mb-0'}>Security Automation, OSINT, Threat Modelling</p>
              <p className={'text-black font-bold font_poppins text-base'}>85%</p>
            </div>
            <div className="bar">
              <span className="f6"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;