import React, {useState} from "react";
import {
    ComposableMap,
    Geographies,
    Geography
  } from "react-simple-maps";
import ReactTooltip from "react-tooltip";

  const Map = () => {

    const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

    const [content, setContent] = useState('');

    const defaultCountries = ['India', 'United States of America', 'United Kingdom', 'Indonesia', 'Kazakhstan', 'Germany', 'Sweden']

    const checkCountries= (country) => {
      return defaultCountries.find(el => el === country) ? "#D2CDFF" : "#EEF3FE";
    }

    return (
      <div>
        <ReactTooltip>{content}</ReactTooltip>
        <ComposableMap data-tip={''} data-face={'tooltip_id'}>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() => {
                        const {name} = geo.properties;
                        if(geo.properties.name === 'India' ||
                          geo.properties.name === 'United States of America' ||
                          geo.properties.name === 'Indonesia' ||
                          geo.properties.name === 'Kazakhstan' ||
                          geo.properties.name === 'United Kingdom' ||
                          geo.properties.name === 'Germany' ||
                          geo.properties.name === 'Sweden'
                        ) {
                          return setContent(`${name}`);
                        }
                      }}
                      onMouseLeave={() => {
                        setContent("");
                      }}
                      style={{
                        default: {
                          fill: checkCountries(geo.properties.name),
                          outline: "none"
                        },
                        hover: {
                          fill: checkCountries(geo.properties.name),
                          outline: "none"
                        },
                        pressed: {
                          fill: checkCountries(geo.properties.name),
                          outline: "none"
                        }
                      }}
                    />
                )
              })
            }
          </Geographies>
        </ComposableMap>
      </div>
    );
  };

  export default Map;
