import React, {useState} from 'react';
import './lineElleps_css.css';

function LineEllipseData({ limit, id, children }) {
  const [isReadMoreShow, setIsReadMoreShow] = useState(false)
  const toggleBtn = (id) => {
      setIsReadMoreShow(prevState => !prevState)
  }
  return (
    <div className={''}>
      <p>{isReadMoreShow ? children : children.substr(0, limit)}{!isReadMoreShow ? '...' : null}</p>
      <button id={id} onClick={()=>toggleBtn(id)} className={'flex items-center gap-2 mt-6'}>
        <span className={'text-[16px] font-bold text-[#272728]'}>
          {
            isReadMoreShow ? 'Read Less' : 'Read More'
          }
        </span>
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.3125 3.125L9.6875 7.5L5.3125 11.875" stroke="#272728" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    </div>
  );
}

export default LineEllipseData;