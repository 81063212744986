import React from "react";
import "./custom_resumr_experience__css.css";

function ResumeExperience() {
  return (
    <div>
      {/*Items*/}
      <div className={"custom__hover pb-[100px]"}>
        <span className={"custom_after mb-3"}>Jan, 2019 - Present</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
          ThriveDX (Previously HackerU)
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
          Head of Cyber Security (Red Team)
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Managing the entire Red Teaming Program for HackerU.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Training advanced cyber security topics.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Researching new attack vectors in Red Teaming.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Mentoring 100s of students for career in information security.
          </li>
        </div>
      </div>
      {/*Items*/}
      <div className={"custom__hover pb-[100px]"}>
        <span className={"custom_after mb-3"}>Nov, 2018 - Jan, 2019</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
          Traveloka
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
          Lead Security Engineer (Product Security Team)
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Performing vulnerability assessments and penetration testing on
            regular basis on various products including web, mobile and aws.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Automate security checks and integrate into the current CI/CD
            pipelines.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Working with developers to address security issues.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Educating developers on secure coding practices with workshops,
            talks, and lessons.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Working on security automation Web, Mobile (iOS, Android), AWS
            Security assessments.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Leading and mentoring team of 7 security engineers.
          </li>
        </div>
      </div>
      {/*Items*/}
      <div className={"custom__hover pb-[100px]"}>
        <span className={"custom_after mb-3"}>Oct, 2017 - Nov, 2018</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
          khoros
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
          Senior Security Engineer
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Working on end to end Application Security, AWS Security. Periodic
            security audits, Actions on findings.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Handled Lithium (B2B) bug bounty program.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Conducted training for developers on Secure Web App Development,
            Secure Mobile App Development.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Performed security assessment of each sprint, mobile sdk (iOS and
            Android).
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Communicating to clients, (senior) management, and fellow engineers
            regarding security issues.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Support pre-sales in the scoping of security requirements for
            clients.
          </li>
        </div>
      </div>
      {/*Items*/}
      <div className={"custom__hover pb-[100px]"}>
        <span className={"custom_after mb-3"}>Feb, 2016 - Oct, 2017</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
          Philips Healthcare
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
          Senior Software Engineer (Product Security)
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Performed security assessment of healthcare devices.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Visited Philips development center across the world and conducted
            secure development training for developers.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Worked on various IoT Pentest.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Presented Philips at various security conferences
          </li>
        </div>
      </div>
      {/*Items*/}
      <div className={"custom__hover pb-[100px]"}>
        <span className={"custom_after mb-3"}>Jan, 2013 - Dec, 2015</span>
        <h4 className={"text-[24px] font_kanit font-semibold leading-[38px]"}>
          Persistent Systems Ltd
        </h4>
        <p className={"text-[#A5A5A5] text-[16px] font-normal"}>
          Domain Consultant (Security)
        </p>
        <div className={"mt-8"}>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Conducted VA PT assessments for telecom, financial, healthcare and
            Social clients.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Performed Network, Web, Android and iOS Pentest for clients.
          </li>
          <li
            data-aos="fade-up"
            data-aos-duration="300"
            className={
              "text-[#272728] leading-[160%] font-normal flex gap-[14px] pb-[18px]"
            }
          >
            <span className={"mt-1"}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="7"
                  cy="7"
                  r="5"
                  fill="white"
                  stroke="#5E3AEE"
                  strokeWidth="4"
                />
              </svg>
            </span>
            Worked on Source Code review for Java based enterprise application.
          </li>
        </div>
      </div>
    </div>
  );
}

export default ResumeExperience;
