import React from 'react';
import IMG1 from '../../../assets/1/img_1.png'
import IMG2 from '../../../assets/1/img_2.png'
import IMG3 from '../../../assets/1/img_3.png'
import IMG4 from '../../../assets/1/img_4.png'
import IMG5 from '../../../assets/1/img_5.png'
import IMG6 from '../../../assets/1/img_6.png'

function About() {
  return (
    <div className={'mt-16 md:mb-[200px] xm:mb-[100px]'}>
      <div className={'container'}>
        <div className={'grid grid-flow-row lg:grid-cols-6 sm:grid-cols-3 xm:grid-cols-1 xl:gap-10 lg:gap-7 xm:gap-10 overflow-hidden md:mb-[160px] xm:mb-[100px]'}>
          <li className={'flex justify-center items-center'}><img className={' h-full'} src={IMG1} alt={''} /></li>
          <li className={'flex justify-center items-center'}><img className={' h-full'} src={IMG2} alt={''} /></li>
          <li className={'flex justify-center items-center'}> <img className={' h-full'} src={IMG3} alt={''} /></li>
          <li className={'flex justify-center items-center'}><img className={' h-full'} src={IMG4} alt={''} /></li>
          <li className={'flex justify-center items-center'}><img className={' h-full'} src={IMG5} alt={''} /></li>
          <li className={'flex justify-center items-center'}><img className={' h-full'} src={IMG6} alt={''} /></li>
        </div>
        <div className={'grid grid-flow-row sm:grid-cols-12 xm:grid-cols-1 gap-10 items-center'}>
          <h1 data-aos="flip-left" className={'col-span-5 lg:text-[72px] md:text-[60px] xm:text-[50px] xm:text-center sm:text-left text-[#272728] font-semibold font_kanit'}>About Me</h1>
          <p data-aos="fade-up" className={'col-span-7'}>I have over 10+ years of industry experience in information security and currently working as a Head of Cyber Security (Red Team) for HackerU. In the past, I have worked with Persistent System, Philips Healthcare, Lithium, and Traveloka in various roles as Security Engineer, Lead Product Security. I have a great interest in presenting my security research and I have spoken in 10+ international conferences including AppSec USA, AppSec Israel, Defcon 25 (AppSec Village), BruCON, SEC-T, c0c0n, GroundZero, and many more!</p>
        </div>
      </div>
    </div>
  );
}

export default About;