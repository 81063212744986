import React, {useState} from 'react';
import './custom_gallery__css.css';

import Img2 from '../../assets/portfolio_img/2.png';
import Img3 from '../../assets/portfolio_img/3.png';
import Img4 from '../../assets/portfolio_img/4.png';
import Img5 from '../../assets/portfolio_img/5.png';
import Img6 from '../../assets/portfolio_img/6.png';
import Img7 from '../../assets/portfolio_img/7.png';
import Img8 from '../../assets/portfolio_img/8.png';
import Img9 from '../../assets/portfolio_img/9.png';
import Img10 from '../../assets/portfolio_img/10.png';
import Img11 from '../../assets/portfolio_img/11.png';
import Img12 from '../../assets/portfolio_img/12.png';
import Img13 from '../../assets/portfolio_img/13.png';
import Img14 from '../../assets/portfolio_img/14.png';
import Img15 from '../../assets/portfolio_img/15.png';

function PortfolioImg() {

  let data = [
    {
      id: 1,
      imgSrc: Img7,
    },
    {
      id: 2,
      imgSrc: Img2,
    },
    {
      id: 3,
      imgSrc: Img3,
    },
    {
      id: 4,
      imgSrc: Img4,
    },
    {
      id: 5,
      imgSrc: Img5,
    },
    {
      id: 6,
      imgSrc: Img6,
    },
    {
      id: 7,
      imgSrc: Img7,
    },
    {
      id: 8,
      imgSrc: Img8,
    },
    {
      id: 9,
      imgSrc: Img9,
    },
    {
      id: 10,
      imgSrc: Img10,
    },
    {
      id: 11,
      imgSrc: Img11,
    },
    {
      id: 12,
      imgSrc: Img12,
    },
    {
      id: 13,
      imgSrc: Img13,
    },
    {
      id: 14,
      imgSrc: Img14,
    },
    {
      id: 15,
      imgSrc: Img15,
    },
  ]

  const [model, setModel] = useState(false)
  const [tempImgSrc, setTempImgSrc] = useState('')

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc)
    setModel(true)
  }

  return (
    <div className={'gallery container pt-[100px] sm:pb-[200px] xm:pb-[100px]'}>
      {data.map((item)=>{
        return (
          <>
            <div key={item.id} className={model ? 'model open' : 'model'}>
              <img src={tempImgSrc} alt={'SingleImg'} />
              <button className={'close_btn'} onClick={() => setModel(false)}>
                <span>
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <line x1="16.4565" y1="6.41586" x2="6.41563" y2="16.4568" stroke="#fff" strokeWidth="1.8" strokeLinecap="round"/>
                  <line x1="6.41488" y1="6.17139" x2="16.4558" y2="16.2123" stroke="#fff" strokeWidth="1.8" strokeLinecap="round"/>
                  </svg>
                </span>
              </button>
            </div>
            <div className={'pics'} key={item.id} onClick={()=> getImg(item.imgSrc)}>
              <img key={item.id} className={'relative rounded-[15px]'} src={item.imgSrc} alt={''} style={{width: '100%'}} />
              <span className={'absolute_icon'}>
              <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M40 20H34M40 20V26M40 20L33 27M20 40H26M20 40V34M20 40L27 33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M20 20H26M20 20V26M20 20L27 27M40 40H34M40 40V34M40 40L33 33" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path opacity="0.5" d="M10 30C10 20.5719 10 15.8579 12.9289 12.9289C15.8579 10 20.5719 10 30 10C39.4281 10 44.1421 10 47.0711 12.9289C50 15.8579 50 20.5719 50 30C50 39.4281 50 44.1421 47.0711 47.0711C44.1421 50 39.4281 50 30 50C20.5719 50 15.8579 50 12.9289 47.0711C10 44.1421 10 39.4281 10 30Z" stroke="white" strokeWidth="1.5"/>
              </svg>
            </span>
            </div>

          </>
        )
      })}
    </div>
  );
}

export default PortfolioImg;