import React from 'react';
import LineEllipseData from "./LineEllips_data";
import './custom_scrollbar__css.css';

function Works() {
  return (
    <div className={'mb-10'}>
      <div className={'container'}>
        <h1 className={'text-center md:text-[72px] xm:text-[50px] font-semibold mb-[60px]'}>What I Do</h1>
        <div className={'grid grid-flow-row xl:grid-cols-3 md:grid-cols-2 xm:grid-cols-1 gap-[30px]'}>
          <div data-aos="zoom-in-up" className={'border border-[#D7DFDD] rounded-[18px] lg:px-[30px] lg:py-[46px] sm:px-[20px] sm:py-[30px] xm:px-[30px] xm:py-10 custom__scroller mb-[20px]'}>
          <span className={'w-[84px] h-[84px] rounded-full bg-[#ECF6F9] flex items-center justify-center mb-[26px]'}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_18_220)">
              <path d="M0.0463608 16.2587C-0.181641 15.215 0.461395 14.5727 1.60496 14.0803C5.54868 12.3854 9.46033 10.6173 13.3738 8.85104C14.0221 8.55844 14.5886 8.54595 15.237 8.83855C19.2573 10.6512 23.2811 12.4585 27.3246 14.223C28.2544 14.628 28.6124 15.2293 28.5715 16.2212C28.2099 25.0223 24.6028 32.1695 17.7396 37.6682C16.9523 38.2997 16.1579 38.9224 15.3706 39.5522C14.7792 40.025 14.1433 40.1873 13.4931 39.7146C9.12011 36.5388 5.28505 32.8849 2.9035 27.9072C1.17746 24.2962 0.219143 20.5067 0.0463608 16.2587ZM13.9366 23.5486C13.4397 22.9634 13.0318 22.4121 12.5508 21.934C11.89 21.2792 11.0777 21.2614 10.4881 21.8145C9.87358 22.3907 9.8718 23.2507 10.538 23.9465C11.4001 24.8475 12.2818 25.7288 13.1796 26.5923C14.0524 27.4327 14.984 27.3238 15.6128 26.2908C17.0004 24.0125 18.3791 21.7288 19.715 19.4202C19.9163 19.0723 20.0553 18.4978 19.9039 18.182C19.7008 17.7592 19.1967 17.2328 18.803 17.2168C18.3185 17.1989 17.6256 17.5326 17.3567 17.9376C16.1971 19.6914 15.155 21.5236 13.9366 23.5486Z" fill="#86C7DC"/>
              <path d="M15.406 7.36127C16.106 6.1552 16.7081 5.0651 17.3636 4.0089C17.8713 3.18999 18.5553 3.01515 19.421 3.5147C19.9126 3.79838 20.2617 3.78589 20.6892 3.4255C21.0027 3.16145 21.3732 2.93843 21.7544 2.79035C22.4972 2.50132 22.9479 2.13736 22.9069 1.2007C22.8748 0.474561 23.4805 0.0320985 24.2197 0.0213937C26.1488 -0.00715221 28.0797 -0.00715222 30.0088 0.0196096C30.8941 0.0320985 31.4178 0.583392 31.4071 1.51114C31.4 2.066 31.5407 2.38892 32.0893 2.56377C32.4491 2.67973 32.8018 2.87777 33.1011 3.10971C33.7708 3.6271 34.3693 3.93754 35.2279 3.36305C35.8264 2.96341 36.4819 3.32559 36.8328 3.89829C37.8535 5.56823 38.8367 7.26314 39.7879 8.9759C40.1994 9.71453 39.9838 10.4317 39.1894 10.8617C38.6087 11.1775 38.4733 11.5611 38.5767 12.1516C38.6319 12.471 38.6622 12.8135 38.6069 13.1293C38.4626 13.95 38.5428 14.5656 39.4103 15.0241C40.0391 15.3577 40.162 16.0892 39.7879 16.7511C38.8385 18.43 37.882 20.1052 36.8809 21.7538C36.4071 22.5352 35.7035 22.7029 34.8877 22.2159C34.3568 21.9001 33.9846 21.9465 33.5428 22.3176C33.2738 22.5441 32.955 22.7279 32.6326 22.8706C31.888 23.2007 31.2592 23.4933 31.3447 24.5638C31.3964 25.2043 30.7783 25.6521 30.0711 25.6949C29.4815 25.7306 28.8866 25.702 28.3789 25.702C28.8296 23.5968 29.3657 21.5325 29.6828 19.4362C29.8431 18.3782 30.1869 17.6664 31.0205 16.9474C33.4751 14.8332 33.4288 10.9366 31.0579 8.70828C28.6337 6.42817 24.8307 6.67081 22.7626 9.23995C22.5043 9.56109 22.2728 9.90186 21.9895 10.289C19.8218 9.32559 17.6664 8.36751 15.406 7.36127Z" fill="#86C7DC"/>
              </g>
              <defs>
              <clipPath id="clip0_18_220">
              <rect width="40" height="40" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </span>
            <h4 className={'mb-5 font-bold lg:text-[22px] xm:text-[20px]'}>Security Engineering</h4>
            <LineEllipseData limit={140} id={1}>
              My core skills are Security Engineering, Vulnerability Assessment, Penetration Testing, Web/API/Network/Cloud Pentesting, Security Architecture, Threat Modelling, DevOps Security and also integrating security in the development lifecycle by closely working with developers.
            </LineEllipseData>
          </div>
          <div data-aos="zoom-in-up" className={'border border-[#D7DFDD] rounded-[18px] lg:px-[30px] lg:py-[46px] sm:px-[20px] sm:py-[30px] xm:px-[30px] xm:py-10 custom__scroller mb-[20px]'}>
          <span className={'w-[84px] h-[84px] rounded-full bg-[#F2EFFE] flex items-center justify-center mb-[26px]'}>
            <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_63_533)">
              <path d="M33.9992 34.7524C25.0472 34.7524 16.2022 34.7524 7.24272 34.7524C7.24272 34.4073 7.24272 34.1083 7.24272 33.8092C7.24272 30.7655 7.23718 27.7237 7.24456 24.68C7.2501 22.6921 8.04563 21.9058 10.0631 21.9039C17.1343 21.8984 24.2055 21.8984 31.2767 21.9039C33.2314 21.9058 34.0417 22.7069 34.0472 24.6357C34.0564 27.7089 34.0509 30.784 34.049 33.8572C34.0454 34.1286 34.0177 34.3999 33.9992 34.7524Z" fill="#5E3AEE"/>
              <path d="M13.6846 20.4365C13.153 20.4365 12.6362 20.4605 12.123 20.4328C6.72966 20.1467 5.52806 21.4462 5.74402 26.8359C5.86215 29.7817 5.76432 32.7368 5.76432 35.6882C5.76432 36.0205 5.76432 36.3509 5.76432 36.9286C4.88019 36.6794 4.10127 36.5558 3.40541 36.2457C0.727173 35.0533 -0.605483 32.113 0.262035 29.2704C1.25322 26.0182 2.27394 22.7733 3.36295 19.5524C4.45935 16.3075 7.2594 14.7423 10.9362 15.0302C11.106 15.0431 11.3441 15.1871 11.4124 15.3348C12.171 16.9997 12.9038 18.6756 13.6846 20.4365Z" fill="#5E3AEE"/>
              <path d="M35.5239 36.8087C35.5239 34.6325 35.5239 32.6077 35.5239 30.581C35.5239 28.5211 35.5498 26.4612 35.5166 24.4013C35.476 21.9151 34.0141 20.4772 31.5278 20.4403C30.221 20.42 28.9142 20.4366 27.5981 20.4366C28.3789 18.6813 29.0988 17.0311 29.8574 15.3995C29.9423 15.2167 30.2542 15.0506 30.472 15.034C34.3666 14.7461 36.96 16.494 38.145 20.1893C39.0992 23.1647 40.0609 26.1382 40.9801 29.1247C42.0488 32.5985 40.0849 35.9357 36.552 36.7017C36.2604 36.7663 35.9559 36.7663 35.5239 36.8087Z" fill="#5E3AEE"/>
              <path d="M33.8977 36.3177C34.4256 38.4219 33.5728 40.0296 31.201 40.0001C24.1612 39.9078 17.1177 39.9078 10.076 40.0001C7.706 40.0315 6.85325 38.4072 7.39222 36.3177C16.2261 36.3177 25.0674 36.3177 33.8977 36.3177Z" fill="#5E3AEE"/>
              <path d="M29.1707 10.0134C23.4562 6.94943 17.8099 6.97343 12.0308 10.0614C12.7691 7.50501 13.8119 5.28084 15.307 3.27817C15.7463 2.69121 16.2392 2.13193 16.7763 1.63357C19.1075 -0.529687 22.1069 -0.53707 24.4861 1.58004C26.4187 3.29847 28.641 7.25768 29.1707 10.0134Z" fill="#5E3AEE"/>
              <path d="M15.4104 10.0189C15.6337 11.4955 15.8109 12.7396 16.014 13.98C16.396 16.2964 18.3249 17.9503 20.6303 17.9558C22.9356 17.9613 24.8885 16.3075 25.2687 14.0077C25.4736 12.7673 25.6471 11.5214 25.8723 10.0282C27.0222 10.652 28.0835 11.2095 29.1153 11.8149C29.2298 11.8832 29.2464 12.2486 29.1965 12.448C28.5247 15.117 27.432 17.6106 25.9535 19.9289C25.7929 20.18 25.3832 20.4107 25.086 20.4144C22.1327 20.4494 19.1776 20.4495 16.2244 20.4162C15.9217 20.4125 15.5064 20.2021 15.3421 19.9548C13.7935 17.6365 12.7838 15.0727 12.0769 12.3889C12.0252 12.1933 12.1415 11.8167 12.2965 11.7244C13.2674 11.1504 14.2697 10.6336 15.4104 10.0189Z" fill="#5E3AEE"/>
              <path d="M24.4308 9.68852C24.1761 11.2759 24.0284 12.8024 23.6666 14.2753C23.3418 15.5969 21.9187 16.4958 20.5823 16.4589C19.1925 16.4219 17.8174 15.4141 17.5405 14.0741C17.2507 12.6695 17.0993 11.2353 16.8723 9.71067C19.3992 9.03881 21.8449 9.08311 24.4308 9.68852Z" fill="#5E3AEE"/>
              </g>
              <defs>
              <clipPath id="clip0_63_533">
              <rect width="41.2717" height="40" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </span>
            <h4 className={'mb-5 font-bold lg:text-[22px] xm:text-[20px]'}>Offensive Security / Red <br /> Teaming</h4>
            <LineEllipseData limit={120} id={2}>
              I'm also a Red Teaming professional with skillsets of Exploit development, lateral movement, Antivirus bypass techniques, AD Exploitation, Mobile Malwares, C2 Framework and Adversary Emulation (APT).
            </LineEllipseData>
          </div>
          <div data-aos="zoom-in-up" className={'border border-[#D7DFDD] rounded-[18px] lg:px-[30px] lg:py-[46px] sm:px-[20px] sm:py-[30px] xm:px-[30px] xm:py-10 custom__scroller mb-[20px]'}>
          <span className={'w-[84px] h-[84px] rounded-full bg-[#F8F5F2] flex items-center justify-center mb-[26px]'}>
            <svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_63_559)">
              <path d="M39.0999 24.2566C39.0999 22.3241 39.1233 20.5141 39.0805 18.7042C39.0727 18.3484 38.8316 17.981 38.647 17.6485C37.8751 16.2643 37.1072 14.8782 36.2771 13.529C36.1274 13.284 35.688 13.0721 35.3925 13.0838C33.4445 13.1654 31.6676 11.9951 31.1349 10.2065C30.6061 8.43735 31.343 6.53213 32.9216 5.57757C34.5352 4.60163 36.6076 4.85242 37.9218 6.18025C39.2399 7.5139 39.4654 9.53578 38.4487 11.1766C38.2018 11.5751 37.9996 11.8745 38.3067 12.3839C39.3585 14.1278 40.7252 15.8055 41.2521 17.7069C41.7711 19.5771 41.4154 21.6903 41.4465 23.6966C41.4484 23.8522 41.4873 24.0077 41.5126 24.1982C42.055 24.241 42.576 24.2235 43.0718 24.3285C45.0139 24.7367 46.3962 26.5564 46.2193 28.4189C46.0229 30.4796 44.3471 32.0116 42.2144 32.0174C36.2538 32.031 30.2912 32.0233 24.3306 32.0233C17.5592 32.0233 10.7879 32.0291 4.01658 32.0194C2.15218 32.0174 0.637725 30.886 0.155587 29.2004C-0.314887 27.5538 0.28973 25.7496 1.79253 24.9137C2.51768 24.5112 3.43918 24.2838 4.27126 24.2799C15.4479 24.2391 26.6265 24.2527 37.8032 24.2527C38.1882 24.2566 38.5711 24.2566 39.0999 24.2566Z" fill="#B59B79"/>
              <path d="M41.1374 33.2441C40.5075 34.9297 40.0273 36.5744 39.2866 38.0947C38.6198 39.4633 37.2375 39.9708 35.7833 39.9766C27.3304 40.0077 18.8793 40.0057 10.4264 39.9824C8.39088 39.9766 7.04167 38.9132 6.38068 36.9729C5.96658 35.7579 5.56415 34.5409 5.13062 33.2441C17.153 33.2441 29.0509 33.2441 41.1374 33.2441Z" fill="#B59B79"/>
              <path d="M31.124 22.6313C22.7332 22.6313 14.4435 22.6313 6.04693 22.6313C5.75337 19.7248 6.85568 17.6096 8.82895 15.9805C10.8547 14.3066 13.1993 13.8731 15.6489 14.0986C16.0377 14.1336 16.4673 14.5535 16.7473 14.9209C17.3558 15.7141 17.8788 16.589 18.5359 17.5824C19.1852 16.6376 19.7451 15.7491 20.3808 14.9346C20.6666 14.5691 21.0904 14.1336 21.4773 14.1005C24.2535 13.8614 26.8547 14.4233 28.9991 16.5909C30.5505 18.154 31.3359 20.0845 31.124 22.6313Z" fill="#B59B79"/>
              <path d="M18.5184 -2.6251e-05C21.7942 -0.0194673 24.4635 2.92197 24.4343 6.51468C24.4032 10.0743 21.7767 12.9652 18.5729 12.9652C15.3748 12.9633 12.7425 10.0588 12.7191 6.50496C12.6958 2.96085 15.3204 0.0194148 18.5184 -2.6251e-05Z" fill="#B59B79"/>
              </g>
              <defs>
              <clipPath id="clip0_63_559">
              <rect width="46.2328" height="40" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </span>
            <h4 className={'mb-5 font-bold lg:text-[22px] xm:text-[20px]'}>Speaker / Trainer</h4>
            <LineEllipseData limit={150} id={3}>
              I’m a frequent speaker at security conferences and meetup groups! I have presented 20+ infosec talks and provided specialized training for 500+ Professionals.
            </LineEllipseData>
          </div>
          <div data-aos="zoom-in-up" className={'border border-[#D7DFDD] rounded-[18px] lg:px-[30px] lg:py-[46px] sm:px-[20px] sm:py-[30px] xm:px-[30px] xm:py-10 custom__scroller mb-[20px]'}>
          <span className={'w-[84px] h-[84px] rounded-full bg-[#FFF5DC] flex items-center justify-center mb-[26px]'}>
            <svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_63_573)">
              <path d="M0 20.5843C0.0505957 10.4651 7.73135 1.64026 17.7689 0.221944C28.6682 -1.31714 38.5654 5.2554 41.2453 15.812C43.731 25.6047 38.1655 36.2477 28.6878 39.8221C27.6171 40.2252 26.9936 39.9298 26.6395 38.8216C25.3893 34.898 24.1423 30.9727 22.9019 27.0458C22.5804 26.0258 22.7256 25.6585 23.6282 25.0726C25.683 23.7359 26.5742 21.5619 25.9866 19.3129C25.4317 17.1878 23.4683 15.6275 21.149 15.4676C18.9522 15.3174 16.7505 16.8239 16.0013 18.9913C15.2375 21.1996 16.0486 23.5776 18.0284 24.9339C19.2868 25.7956 19.3243 25.9099 18.8477 27.4147C17.6546 31.1865 16.4632 34.9584 15.2652 38.7286C14.8686 39.9771 14.2664 40.2823 13.0553 39.7535C5.63245 36.5121 1.3008 30.9058 0.195854 22.8562C0.0913987 22.1038 0.0636527 21.3416 0 20.5843Z" fill="#FFC023"/>
              </g>
              <defs>
              <clipPath id="clip0_63_573">
              <rect width="41.8378" height="40" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </span>
            <h4 className={'mb-5 font-bold lg:text-[22px] xm:text-[20px]'}>Open Source Contribution</h4>
            <LineEllipseData limit={145} id={4}>
              I lead an open source project - OWASP iGoat. You can check more about the project at https://igoatapp.com/. This project aims to train its users in pentesting and securing iOS Applications (both Objective C and Swift).
            </LineEllipseData>
          </div>
          <div data-aos="zoom-in-up" className={'border border-[#D7DFDD] rounded-[18px] lg:px-[30px] lg:py-[46px] sm:px-[20px] sm:py-[30px] xm:px-[30px] xm:py-10 custom__scroller mb-[20px]'}>
          <span className={'w-[84px] h-[84px] rounded-full bg-[#EDF8F0] flex items-center justify-center mb-[26px]'}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_63_583)">
              <path d="M16.606 26.6667C15.7575 26.6667 15.0303 27.394 15.0303 28.2425C15.0303 29.091 15.7575 29.8183 16.606 29.8183C17.4545 29.8183 18.1818 29.091 18.1818 28.2425C18.1818 27.3334 17.4545 26.6667 16.606 26.6667Z" fill="#65C67F"/>
              <path d="M29.8788 21.697H3.33335C2.54547 21.697 1.8182 22.0001 1.39396 22.5455C1.03032 22.9697 0.90911 23.4546 0.969716 24.0001L3.15153 35.394C3.33335 36.4243 4.30305 37.1516 5.51517 37.1516H27.6364C28.8485 37.1516 29.8182 36.4243 30 35.394L32.1818 23.9394C32.2424 23.394 32.1212 22.9091 31.7576 22.4849C31.3334 22.0001 30.6061 21.697 29.8788 21.697ZM16.6061 31.0304C15.0909 31.0304 13.8182 29.7576 13.8182 28.2425C13.8182 26.7273 15.0303 25.4546 16.6061 25.4546C18.1818 25.4546 19.394 26.6667 19.394 28.2425C19.394 29.7576 18.1212 31.0304 16.6061 31.0304Z" fill="#65C67F"/>
              <path d="M27.6364 38.1818H5.57579C5.21215 38.1818 4.96973 38.4242 4.96973 38.7879C4.96973 39.1515 5.21215 39.3939 5.57579 39.3939H27.6364C28 39.3939 28.2425 39.1515 28.2425 38.7879C28.2425 38.4242 27.9394 38.1818 27.6364 38.1818Z" fill="#65C67F"/>
              <path d="M12.4243 9.39392C13.2122 11.3333 14.7273 13.2121 16.6061 13.2121C18.4849 13.2121 20.0001 11.2727 20.7879 9.39392C21.1516 9.33331 21.5758 9.03028 21.7576 8.36362C22.0607 7.51513 22.0001 6.1818 21.2122 5.57574C20.7879 3.09089 18.8485 1.27271 16.6061 1.27271C14.3031 1.27271 12.4243 3.09089 12.0001 5.63634C11.2728 6.2424 11.2122 7.57574 11.4546 8.42422C11.697 9.03028 12.0607 9.33331 12.4243 9.39392Z" fill="#65C67F"/>
              <path d="M6.84847 20.4849H13.697H26.303C26.4848 20.4849 26.6667 20.4243 26.7879 20.2425C26.9091 20.0607 26.9697 19.9395 26.9091 19.7576L26.4848 17.5758C26.303 16.3637 25.3333 15.3334 24 14.9092C22.606 14.5455 21.1515 14.2425 19.6363 14.0607C19.4545 14.0607 19.2121 14.1213 19.0909 14.3031C18.9697 14.4849 18.9091 14.6667 18.9697 14.8485C19.3939 16.2425 19.3939 17.7576 19.0303 19.2122H18.3636L18.303 18.1819L18.7273 17.2122C18.7879 17.1516 18.7879 17.0304 18.7879 16.9698V15.5152C18.7879 15.2122 18.4848 14.9092 18.1818 14.9092H15.0909C14.7273 14.9092 14.4848 15.2122 14.4848 15.5152V16.9698C14.4848 17.0304 14.4848 17.1516 14.5454 17.2122L14.9697 18.1819L14.9091 19.2122H14.2424C13.8182 17.7576 13.8788 16.2425 14.303 14.8485C14.3636 14.6667 14.303 14.4243 14.1818 14.3031C14.0606 14.1213 13.8788 14.0607 13.6363 14.0607C12.0606 14.2425 10.5454 14.4849 9.2121 14.9092C7.93938 15.2728 6.90907 16.3031 6.78786 17.5152L6.36362 19.7576C6.30301 19.9395 6.36362 20.1213 6.48483 20.2425C6.48483 20.4243 6.66665 20.4849 6.84847 20.4849Z" fill="#65C67F"/>
              <path d="M35.8182 0.606079H28.4243C26.6667 0.606079 25.2122 2.06062 25.2122 3.8182V6.72729C25.2122 8.24244 26.2425 9.51517 27.6364 9.8182L27.2122 11.8182C27.1516 12.0606 27.2728 12.303 27.4546 12.4243C27.697 12.6061 27.9394 12.5455 28.1213 12.4849L32.4243 10H35.8788C37.6364 10 39.0909 8.54547 39.0909 6.7879V3.8182C39.0909 2.06062 37.6364 0.606079 35.8182 0.606079ZM29.2122 5.57578H27.8788C27.5758 5.57578 27.2728 5.33335 27.2728 4.96972C27.2728 4.60608 27.5758 4.36365 27.8788 4.36365H29.2122C29.5152 4.36365 29.8182 4.60608 29.8182 4.96972C29.8182 5.33335 29.5152 5.57578 29.2122 5.57578ZM32.8485 5.57578H31.5152C31.2122 5.57578 30.9091 5.33335 30.9091 4.96972C30.9091 4.60608 31.2122 4.36365 31.5152 4.36365H32.8485C33.1516 4.36365 33.4546 4.60608 33.4546 4.96972C33.4546 5.33335 33.1516 5.57578 32.8485 5.57578ZM36.4243 5.57578H35.0909C34.7879 5.57578 34.4849 5.33335 34.4849 4.96972C34.4849 4.60608 34.7879 4.36365 35.0909 4.36365H36.4243C36.7879 4.36365 37.0303 4.60608 37.0303 4.96972C37.0303 5.33335 36.7879 5.57578 36.4243 5.57578Z" fill="#65C67F"/>
              </g>
              <defs>
              <clipPath id="clip0_63_583">
              <rect width="40" height="40" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </span>
            <h4 className={'mb-5 font-bold lg:text-[22px] xm:text-[20px]'}>Security Mentorship</h4>
           <LineEllipseData limit={150} id={5}>
             In the information security field, it’s always good to have a mentor who can give you direction for your career/skills. Based on my experience and skills, I mentor a group of infosec professionals who have started their career in information security. You can always reach out to me if you need any advice from my side!
           </LineEllipseData>
          </div>
          <div data-aos="zoom-in-up" className={'border border-[#D7DFDD] rounded-[18px] lg:px-[30px] lg:py-[46px] sm:px-[20px] sm:py-[30px] xm:px-[30px] xm:py-10 custom__scroller mb-[20px]'}>
          <span className={'w-[84px] h-[84px] rounded-full bg-[#FFF0EB] flex items-center justify-center mb-[26px]'}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_63_605)">
              <path d="M35.6902 0H4.30984C1.93336 0 0 1.93344 0 4.30992V28.1589C0 30.5354 1.93336 32.4688 4.30984 32.4688H22.7605V27.5312C22.7605 23.5163 26.0268 20.25 30.0416 20.25C34.0565 20.25 37.3228 23.5164 37.3228 27.5312V32.1466C38.8919 31.5018 40 29.9577 40 28.1589V4.30992C40 1.93344 38.0666 0 35.6902 0V0ZM11.2135 5.10414H28.7865C29.4336 5.10414 29.9584 5.62883 29.9584 6.27602C29.9584 6.9232 29.4336 7.44789 28.7865 7.44789H11.2135C10.5664 7.44789 10.0416 6.9232 10.0416 6.27602C10.0416 5.62883 10.5664 5.10414 11.2135 5.10414ZM18.9114 26.1927H6.19266C5.54555 26.1927 5.02078 25.668 5.02078 25.0209C5.02078 24.3737 5.54555 23.849 6.19266 23.849H18.9115C19.5588 23.849 20.0834 24.3737 20.0834 25.0209C20.0834 25.668 19.5588 26.1927 18.9114 26.1927ZM33.8072 17.4896H6.19266C5.54555 17.4896 5.02078 16.9649 5.02078 16.3177C5.02078 15.6705 5.54555 15.1459 6.19266 15.1459H33.8073C34.4545 15.1459 34.9791 15.6705 34.9791 16.3177C34.9791 16.9649 34.4545 17.4896 33.8072 17.4896ZM33.8072 12.4688H6.19266C5.54555 12.4688 5.02078 11.9441 5.02078 11.2969C5.02078 10.6497 5.54555 10.125 6.19266 10.125H33.8073C34.4545 10.125 34.9791 10.6497 34.9791 11.2969C34.9791 11.9441 34.4545 12.4688 33.8072 12.4688Z" fill="#FD6A3B"/>
              <path d="M30.0417 22.5938C27.3192 22.5938 25.1042 24.8087 25.1042 27.5312C25.1042 30.2537 27.3192 32.4687 30.0417 32.4687C32.7642 32.4687 34.9791 30.2537 34.9791 27.5312C34.9791 24.8087 32.7642 22.5938 30.0417 22.5938Z" fill="#FD6A3B"/>
              <path d="M25.1042 32.8775V38.8281C25.1042 39.2603 25.3421 39.6574 25.7232 39.8613C25.8964 39.9541 26.0864 40 26.276 40C26.5034 40 26.7301 39.9339 26.9261 39.8031L30.0417 37.7261L33.1572 39.8031C33.3534 39.9339 33.58 40 33.8074 40C33.9969 40 34.187 39.9541 34.3602 39.8614C34.7413 39.6575 34.9791 39.2604 34.9791 38.8281V32.8775C33.6804 34.0777 31.9453 34.8125 30.0417 34.8125C28.138 34.8125 26.4029 34.0777 25.1042 32.8775Z" fill="#FD6A3B"/>
              </g>
              <defs>
              <clipPath id="clip0_63_605">
              <rect width="40" height="40" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </span>
            <h4 className={'mb-5 font-bold lg:text-[22px] xm:text-[20px]'}>Security Research and Certifications</h4>
             <LineEllipseData limit={110} id={6}>
               I spend some dedicated time on a few security research projects. I have plans to present my research in upcoming security conferences. Also I currently hold OSCE, OSCP, OSWP and CREST CRT Certifications.
             </LineEllipseData>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Works;