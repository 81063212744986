import React from 'react';
import '../resume/experience/custom_resume_css.css';
import TasksVideo from '../talks/TalksVideo'

function Talks() {
  return (
    <>
      <div className={'sm:pt-[170px] sm:pb-[120px] xm:pt-[100px] xm:pb-[80px] custom_bg'}>
        <h1 className={'text-center sm:text-72 xm:text-[56px] font_kanit font-semibold'}>Talks</h1>
      </div>
      <div>
        <TasksVideo />
      </div>
    </>
  );
}

export default Talks;