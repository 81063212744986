import React from 'react';
import '../resume/experience/custom_resume_css.css';
import PortfolioImg from "../portfolio/PortfolioIMG";

function Portfolio() {
  return (
    <>
      <div className={'sm:pt-[170px] sm:pb-[120px] xm:pt-[100px] xm:pb-[80px] custom_bg'}>
        <h1 className={'text-center sm:text-72 xm:text-[56px] font_kanit font-semibold'}>Portfolio</h1>
      </div>
      <div>
        <PortfolioImg />
      </div>
    </>
  );
}

export default Portfolio;