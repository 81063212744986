import React from 'react';
import './custom_certificate__css.css';
import IMG1 from '../../../assets/2/image1.png';
import IMG2 from '../../../assets/2/image2.png';
import IMG3 from '../../../assets/2/image3.png';
import IMG4 from '../../../assets/2/image4.png';
import IMG5 from '../../../assets/2/image5.png';

function Certificates() {
  return (
    <div className={'container'}>
      <div className={'mb-[60px]'}>
        <h2 className={'text-[48px] font-semibold font_kanit custom_border__certificate'}>Certificates</h2>
      </div>
      <div className={'grid grid-flow-row grid-cols-12'}>
        <div className={'sm:col-start-2 sm:col-span-10 xm:col-span-12'}>
          <div data-aos="zoom-in-up" className={'border mb-[25px] border-[#E8E8E8] rounded-[18px] lg:px-[60px] md:px-[40px] xm:px-[20px] py-[34px] flex xl:gap-[120px] lg:gap-[80px] md:gap-[50px] xm:gap-[30px] md:items-center xm:items-start'}>
            <b className={'text-2xl font-bold font_poppins md:block xm:hidden'}>01</b>
            <img src={IMG1} alt={'01'}/>
            <div>
              <span className={'text-[#A5A5A5] text-base'}>2020</span>
              <h4 className={'text-[#272728] text-[22px] font-semibold mt-3'}>OSCE (Offensive Security Certified Expert)</h4>
            </div>
          </div>
          <div data-aos="zoom-in-up" className={'border mb-[25px] border-[#E8E8E8] rounded-[18px] lg:px-[60px] md:px-[40px] xm:px-[20px] py-[34px] flex xl:gap-[120px] lg:gap-[80px] md:gap-[50px] xm:gap-[30px] md:items-center xm:items-start'}>
            <b className={'text-2xl font-bold font_poppins md:block xm:hidden'}>02</b>
            <img src={IMG2} alt={'02'}/>
            <div>
              <span className={'text-[#A5A5A5] text-base'}>2019</span>
              <h4 className={'text-[#272728] text-[22px] font-semibold mt-3'}>CREST Registered Penetration Tester</h4>
            </div>
          </div>
          <div data-aos="zoom-in-up" className={'border mb-[25px] border-[#E8E8E8] rounded-[18px] lg:px-[60px] md:px-[40px] xm:px-[20px] py-[34px] flex xl:gap-[120px] lg:gap-[80px] md:gap-[50px] xm:gap-[30px] md:items-center xm:items-start'}>
            <b className={'text-2xl font-bold font_poppins md:block xm:hidden'}>03</b>
            <img src={IMG3} alt={'03'}/>
            <div>
              <span className={'text-[#A5A5A5] text-base'}>2018</span>
              <h4 className={'text-[#272728] text-[22px] font-semibold mt-3'}>AWS Certified Cloud Practitioner</h4>
            </div>
          </div>
          <div data-aos="zoom-in-up" className={'border mb-[25px] border-[#E8E8E8] rounded-[18px] lg:px-[60px] md:px-[40px] xm:px-[20px] py-[34px] flex xl:gap-[120px] lg:gap-[80px] md:gap-[50px] xm:gap-[30px] md:items-center xm:items-start'}>
            <b className={'text-2xl font-bold font_poppins md:block xm:hidden'}>04</b>
            <img src={IMG4} alt={'04'}/>
            <div>
              <span className={'text-[#A5A5A5] text-base'}>2017</span>
              <h4 className={'text-[#272728] text-[22px] font-semibold mt-3'}>OSWP (Offensive Security Wireless Professional)</h4>
            </div>
          </div>
          <div data-aos="zoom-in-up" className={'border mb-[25px] border-[#E8E8E8] rounded-[18px] lg:px-[60px] md:px-[40px] xm:px-[20px] py-[34px] flex xl:gap-[120px] lg:gap-[80px] md:gap-[50px] xm:gap-[30px] md:items-center xm:items-start'}>
            <b className={'text-2xl font-bold font_poppins md:block xm:hidden'}>05</b>
            <img src={IMG5} alt={'05'}/>
            <div>
              <span className={'text-[#A5A5A5] text-base'}>2016</span>
              <h4 className={'text-[#272728] text-[22px] font-semibold mt-3'}>OSCP (Offensive Security Certified Professional)</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Certificates;